/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 function search_check(){ 
  var results = 0;

  if(jQuery('#pageSearch').val().length==0){
    jQuery( '.sow-accordion-panel' ).show();
  }
  setTimeout( function() {
    var search = jQuery('#pageSearch').val();

    jQuery('.sow-accordion-panel' ).each(function( index ) {
      if( jQuery(this).text().toLowerCase().indexOf(search) >= 0 || jQuery(this).find('.sow-accordion-panel-border').text().toLowerCase().indexOf(search) >= 0) {
        results += 1;
      }
      else{
        jQuery(this).hide();
      }
    });
    jQuery('#pageSearchResults').html('<p>'+results+' resultaten gevonden</p>')

  },100);

}
  (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        new WOW().init();
        var toggleAffix = function(affixElement, scrollElement, wrapper) {

          var height = affixElement.outerHeight(),
          top = wrapper.offset().top;


          if (scrollElement.scrollTop() > top){
            wrapper.height(height);
            affixElement.addClass("affix");
          }
          else {
            affixElement.removeClass("affix");
            wrapper.height('auto');
          }
        };

        $('[data-toggle="affix"]').each(function() {
          var ele = $(this),
          wrapper = $('<div></div>');

          ele.before(wrapper);
          $(window).on('scroll resize', function() {
            toggleAffix(ele, $(this), wrapper);
          });

    // init
    toggleAffix(ele, $(window), wrapper);
  });



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
       var owl = $('.owl-carousel');
       owl.owlCarousel({
        responsive:{
          0:{
            items:1
          },
          600:{
            items:1
          },
          1000:{
            items:1
          }
        },
        loop:false,
        margin:0,
        nav:false,
        autoplay:false,
        autoplayTimeout:3000,
        autoplayHoverPause:true
      });

       var loaded = 0;



       owl.on('changed.owl.carousel', function(event) {
        if(event.type == 'changed'){
          jQuery(".slide-thumb").removeClass("active");
          var current = event.item.index;
          jQuery("#slide-thumb-"+current+"").addClass("active");
        }
        
      })

        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_maps': {
      init: function() {
        console.log('maps');
        // JavaScript to be fired on the about us page
        $('#mapplic').mapplic({
          source: 'https://stichtingpiv.nl/wp-content/themes/piv-theme/maps-json.php',
          search: false,
          height: 'auto',
          sidebar: true,
          minimap: false,
          lightbox: true,
          thumbholder: true,
          fullscreen: true,
          maxscale: 3
        });

      },
      finalize: function() {


        var showChar = 100;
        var ellipsestext = "...";
        var moretext = "more";
        var lesstext = "less";
        $('.more').each(function() {
          var content = $(this).html();

          if(content.length > showChar) {

            var c = content.substr(0, showChar);
            var h = content.substr(showChar-1, content.length - showChar);

            var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

            $(this).html(html);
          }

        });

        $(".morelink").click(function(){
          if($(this).hasClass("less")) {
            $(this).removeClass("less");
            $(this).html(moretext);
          } else {
            $(this).addClass("less");
            $(this).html(lesstext);
          }
          $(this).parent().prev().toggle();
          $(this).prev().toggle();
          return false;
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


